import * as React from 'react'

import Layout from 'layouts/main'

import styled from 'styled-components'
import { SCREEN } from 'styles/screens'
import { ShadowText } from '../components/styled/typography'

import OneColumn from '../components/program/oneColumn'
import TwoColumns from '../components/program/twoColumns'
import Skills from '../components/program/skills'
import SkillTree from '../components/program/skillTree'
import Reasons from '../components/program/reasons'
import FitnessToJoin from '../components/program/fitnessToJoin'
import Topics from '../components/program/topics'
import WhyBuildUpSection from '../components/program/whyBuildUpSection'
import BuildUpMethod from '../components/program/buildUpMethod'
import HeroVideoSection from '../components/program/heroVideoSection'
import ProgramHeader from '../components/program/programHeader'
import ProgramLead from '../components/program/programLead'
import CareerBoost from '../components/program/careerBoost'
import Faq from '../components/shared/faq'
import { config } from '../config'

import { getTranslation } from '../data/dictionary'

const FixedContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1520px;
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 100px 40px 100px;
  min-height: 800px;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
  }
`

const StyledFixedContainer = styled(FixedContainer)`
  flex-direction: column;
  align-items: stretch;
  .mw-8percent {
    max-width: 8%;
  }
  .mb-100px {
    margin-bottom: 60px !important;
  }

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    h2 {
      font-size: 1.5rem;
    }

    .mb-100px {
      margin-bottom: 20px !important;
    }
  }
`

const ProductManagementPage = ({ pageContext }) => {
  const { program } = pageContext

  return (
    <Layout
      title={`Programs`}
      ogImage={`${config.CDN_BASE_URL}/${program.image}`}
      hideTitlePrefix={true}
    >
      <div className="page">
        <ProgramHeader
          title={program.title}
          description1={program.description1}
          description2={program.description2}
          apply_url={program.apply_url}
          image={program.image}
        />
        <Skills skills={program.skills} title={program.skills_title} />
        <FitnessToJoin
          should={program.should}
          not={program.not}
          title={program.fitness_title}
          description={getTranslation('dfghjkl', program.fitness_description, true)}
          column2bg="#F7FECC"
        />
        <WhyBuildUpSection
          why={program.why}
          scheduleType={program.schedule_type}
        />
        <SkillTree embedCode={program.miro_embed_code} />
        <BuildUpMethod method={program.method} />
        <CareerBoost careerBoost={program.careerBoost} />
        {/*
        
          --Removed 'Who will lead the program' section--

        <ProgramLead
          lead={program.lead}
          lead2={program.lead2}
          dean={program.dean}
        /> */}
        <Reasons />
        {/*        <OneColumn
          title={program.dean.title}
          description={`${program.dean.name}<br/>${program.dean.description}`}
          imgSrc={`${config.CDN_BASE_URL}/${program.dean.image}`}
          imgAlt={program.dean.name}
        />
        <HeroVideoSection message={program.dean.message} />*/}
        {/* {program.showTopics && <Topics learnings={program.learnings} />}
        <TwoColumns cornerstones={program.cornerstones} type="cards" /> */}
        <Container className="mb-120px">
          <StyledFixedContainer className="bg-21px mw-8percent">
            <ShadowText highlight>
              <h3>FAQ</h3>
            </ShadowText>
            <Faq questions={program.faq} />
          </StyledFixedContainer>
        </Container>
      </div>
    </Layout>
  )
}

export default ProductManagementPage
